import React from 'react';
import PropTypes from 'prop-types';
import JSONTeam from '../../content/team.json';

function Card({ photoPath, name, title, description, isLast }) {
  return (
    <div
      className={`flex flex-col flex-initial bg-transparent py-2 px-4 m-2 ${
        isLast ? 'sm:col-start-2' : ''
      }`}
    >
      <div className="p-1 h-40">
        <img
          src={photoPath}
          alt={photoPath}
          className="rounded-full h-full m-auto"
        />
      </div>
      <p className="p-1 text-xl font-bold">{name}</p>
      <p className="p-1 text-colorsecundary text-sm font-bold">{title}</p>
      <p className="p-1" dangerouslySetInnerHTML={{ __html: description }}></p>
    </div>
  );
}
Card.propTypes = {
  photoPath: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  isLast: PropTypes.bool,
};

function Team() {
  return (
    <section id="team" className="text-center flex items-stretch">
      <div className="container m-auto w-full mt-10 p-5">
        <p className="text-5xl p-4 font-light">
          OUR RESIDENT{' '}
          <span className="text-colorsecundary font-bold">TEAM</span>
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3">
          {JSONTeam.team_list.map((info, index) => (
            <Card
              key={info.name}
              photoPath={info.photoPath}
              name={info.name}
              title={info.title}
              description={info.description}
              isLast={index === JSONTeam.team_list.length - 1}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team;

import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Banner from '../components/banner';
import About from '../components/about';
import Portfolio from '../components/portfolio';
import Team from '../components/team';
import ApplyTo from '../components/applyto';
import Hood from '../components/hood';
import Contact from '../components/contact';

function IndexPage() {
  return (
    <Layout>
      <SEO />

      <div>
        <Banner />
        <About />
        <Portfolio />
        <Team />
        <ApplyTo />
        <Hood />
        <Contact />
      </div>
    </Layout>
  );
}

export default IndexPage;

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

function Footer() {
  const { site } = useStaticQuery(graphql`
    query SiteAuthorQuery {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);
  const year = new Date().getFullYear();
  return (
    <footer>
      <nav className="flex flex-col mx-auto text-sm p-3">
        <p className="text-center w-full">
          &copy; {year} {site.siteMetadata.author}
        </p>
        <img
          className="m-auto w-full max-w-sm"
          src="/images/lisboa_2020.png"
          alt="Lisboa 2020"
        />
      </nav>
    </footer>
  );
}

export default Footer;

import React from 'react';
import JSONHood from '../../content/hood.json';

function Hood() {
  return (
    <section
      id="hood"
      className="text-center flex items-stretch bg-fixed bg-lisbon text-white"
    >
      <div className="container m-auto px-8 my-20">
        <h2 className="text-5xl p-4 font-bold">LISBON</h2>
        <div className="flex flex-col py-6">
          <div className="p-0 lg:px-14 text-center text-xl font-thin font-light">
            <p className="pb-5">
              Lisbon is in our hearts! If you visit us, we assure you it will be
              on yours too.
              <br />
              With great golden light all year long and amazing food (and beer…
              and wine…) is one of the hottest hubs for new startups and
              creative minds.
            </p>
            <p className="font-bold p-5">
              Come and meet us in the Hood, where you can go from one meeting
              room to another on a bike or skate. We are not lazy or anything,
              it’s just fun!
            </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
            {JSONHood.image_showcase.map((info) => (
              <img
                key={info.path}
                className="m-auto"
                src={info.path}
                alt={info.alt}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hood;

/*global fathom*/
import React from 'react';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

function Banner() {
  return (
    <section
      id="banner"
      className="text-center min-h-screen bg-fixed bg-banner flex items-stretch fade-in-childs fade-in-self"
    >
      <div className="self-center flex-1 text-white text-center px-4 py-2 max-w-screen-lg m-auto">
        <p className="p-4 font-light antialiased tracking-extrawide text-sm sm:text-base md:text-xl lg:text-xl xl:text-2xl mt-2 md:mt-5 lg:mt-8 xl:mt-10 anim-delay-1">
          WELCOME TO
        </p>
        <h1 className="p-4 font-black antialiased txtban-sm md:txtban-md lg:txtban-lg xl:txtban-xl sm:mt-1 md:mt-5 lg:mt-8 xl:mt-0 anim-delay-2">
          A PLACE WHERE VENTURES BUILD THE FUTURE
        </h1>
        <h2 className="p-4 font-normal antialiased text-sm sm:text-lg md:text-xl lg:text-2xl xl:text-2xl sm:mt-1 md:mt-6 lg:mt-8 xl:mt-0 anim-delay-3">
          A prosperous land for startups, ideas, tech ninjas, partners and
          investors.
        </h2>
        <div className="sm:my-2 md:my-6 lg:my-8 flex items-center justify-center flex-col sm:flex-row">
          <AnchorLink
            className="tracking-widest bg-transparent border-2 font-bold 
            hover:text-colorsecundary border-gray-400 hover:bg-white hover:border-white text-sm antialiased py-2 px-6 sm:py-3 sm:px-8 anim-delay-3"
            key="about"
            to="/#about"
            onAnchorLinkClick={() => {
              fathom.trackEvent('about-header');
            }}
          >
            STARTUP STUDIO
          </AnchorLink>
          <button
            className="mt-4 sm:mt-0 sm:ml-4 tracking-widest bg-transparent border-2 font-bold 
            hover:text-colorsecundary border-gray-400 hover:bg-white hover:border-white text-sm antialiased py-2 px-6 sm:py-3 sm:px-8 anim-delay-3"
            onClick={() => {
              fathom.trackEvent('incubator-header');
              window.open('https://incubator.builduplabs.com', '_blank');
            }}
          >
            STARTUP INCUBATOR
          </button>
        </div>
      </div>
    </section>
  );
}

export default Banner;

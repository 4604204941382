/*global fathom*/
import React from 'react';
import PropTypes from 'prop-types';
import JSONAbout from '../../content/about.json';

function Column({ title, description, link }) {
  return (
    <div className="w-auto">
      <p className="text-3xl">{title}</p>
      <p className="text-lg inline">{description}</p>
      {link ? (
        <a
          href={link.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-lg inline ml-1 text-colorsecundary"
          onClick={() => link.event && fathom.trackEvent(link.event)}
        >
          {link.title}
        </a>
      ) : null}
    </div>
  );
}
Column.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.shape({
    url: PropTypes.string,
    event: PropTypes.string,
    title: PropTypes.string,
  }),
};

function About() {
  return (
    <section id="about" className="text-center flex items-stretch">
      <div className="container m-auto px-8 my-8 font-light">
        <p className="text-5xl p-4">ABOUT</p>
        <div className="grid grid-cols-1 gap-8 py-6 px-8 text-left md:grid-cols-3">
          {JSONAbout.columns.map((info) => (
            <Column key={info.title} {...info} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;

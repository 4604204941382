/*global fathom*/
import React from 'react';
import { FaPhoneSquare, FaEnvelope } from 'react-icons/fa';
import { OutboundLink } from 'gatsby-plugin-google-analytics';

function Contact() {
  return (
    <section
      id="contacts"
      className="text-center flex items-stretch bg-colorsecundary text-white"
    >
      <div className="container m-auto px-8 my-24">
        <p className="text-5xl pb-4 font-light">Contact us</p>
        <p className="w-full md:w-1/2 m-auto py-5 text-lg">
          Build Up Labs specializes in developing digital products (from web to
          mobile apps to new media and APIs) and bring those products to the
          market. We build our own ideas or partner with entrepreneurs or
          corporates on this ventures.
        </p>
        <p className="text-3xl font-bold py-5">Let’s talk…</p>
        <p className="text-xl font-bold py-5">
          HOOD, EDIFÍCIO ALTEJO,
          <br />
          RUA 3 DA MATINHA, 101
          <br />
          1950-326 LISBOA
        </p>
        <p className="text-xl font-bold py-5">
          <OutboundLink
            onClick={() => {
              fathom.trackEvent('contact-phone');
            }}
            href="tel:+351218988160"
          >
            <FaPhoneSquare className="inline-block" /> T.(+351) 218 988 160
          </OutboundLink>
          <br />
          <OutboundLink
            onClick={() => {
              fathom.trackEvent('contact-email');
            }}
            href="mailto:hello@builduplabs.com"
          >
            <span>
              <FaEnvelope className="inline-block" /> hello@builduplabs.com
            </span>
          </OutboundLink>
        </p>
      </div>
    </section>
  );
}

export default Contact;

/*global fathom*/
import React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import JSONPortfolio from '../../content/portfolio.json';

function FilterButton({ label, isActive, click }) {
  return (
    <button
      onClick={click}
      className={`${
        isActive === true ? 'text-colorsecundary border-colorsecundary' : ''
      } flex-initial font-light bg-transparent border py-2 px-4 m-2 border-colorprimary hover:border-colorsecundary `}
    >
      {label}
    </button>
  );
}
FilterButton.propTypes = {
  label: PropTypes.string,
  isActive: PropTypes.bool,
  click: PropTypes.func,
};

class Project extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    stage: PropTypes.string,
    descrition: PropTypes.string,
    image_path: PropTypes.string,
    app_link: PropTypes.string,
  };

  render() {
    const styles = {
      backgroundImage: `url(${this.props.image_path})`,
    };

    const content = (
      <div>
        <div className="opacity-0">
          <img src={this.props.image_path} alt={this.props.title} />
        </div>
        <div className="py-10 px-5 sm:py-10 md:py-5 lg:py-8 xl:py-14 absolute z-10 inset-0 w-full m-auto bg-colorprimary-default-opEE opacity-0 hover:opacity-100 flex flex-col justify-center">
          <h3 className="text-3xl sm:text-3xl md:text-2xl lg:text-3xl text-white text-thick">
            {this.props.title}
          </h3>
          <p className="text-base sm:text-base md:text-sm lg:text-base text-colorsecundary">
            {this.props.stage}
          </p>
          <p className="text-md sm:text-xl md:text-base text-white">
            {this.props.descrition}
          </p>
        </div>
      </div>
    );

    return (
      <motion.div
        initial={{ scale: 0.4, opacity: 0 }}
        animate={{
          scale: 1,
          opacity: 1,
          transition: { delay: 0.4, ease: 'easeInOut' },
        }}
        transition={{ duration: 0.2 }}
        exit={{ scale: 0.6, opacity: 0 }}
        positionTransition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
      >
        <div
          className="relative bg-cover bg-local bg-center card"
          style={styles}
        >
          {this.props.app_link ? (
            <OutboundLink
              target="_blank"
              rel="noopener noreferrer"
              href={this.props.app_link}
              onClick={() => {
                fathom.trackEvent(
                  `portfolio-${this.props.title
                    .toLowerCase()
                    .replace(/ /g, '-')}`,
                );
              }}
            >
              {content}
            </OutboundLink>
          ) : (
            content
          )}
        </div>
      </motion.div>
    );
  }
}

function ProjectList({ projects }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 w-full">
      <AnimatePresence>
        {projects.map((info) => {
          const stg_title =
            info.stage_custom === '' || info.stage_custom === undefined
              ? JSONPortfolio.stages.find((s) => s.id === info.stage_id).title
              : info.stage_custom;
          return (
            <Project
              key={info.id}
              title={info.title}
              stage={stg_title}
              descrition={info.descrition}
              image_path={info.image_path}
              app_link={info.app_link}
            />
          );
        })}
      </AnimatePresence>
    </div>
  );
}

ProjectList.propTypes = {
  projects: PropTypes.array,
};

class Portfolio extends React.Component {
  constructor(props) {
    super(props);

    const stg = JSONPortfolio.stages;
    this.showAll = { id: -1, title: 'SHOW ALL' };
    this.state = {
      activeButton: -1,
      stages: stg,
    };
  }

  state = {
    activeButton: 0,
    stages: [],
  };

  componentDidMount() {
    const stg = this.state.stages;
    stg.unshift(this.showAll);
    this.setState({
      stages: stg,
    });
  }

  getProjects() {
    let projects = [];
    for (let i = 0; i < JSONPortfolio.projects.length; i++) {
      const project = JSONPortfolio.projects[i];
      if (
        this.state.activeButton === project.stage_id ||
        this.state.activeButton === this.showAll.id
      ) {
        projects.push(project);
      }
    }
    return projects;
  }

  render() {
    return (
      <section
        id="portfolio"
        className="text-center flex items-stretch bg-colorprimary-lighter"
      >
        <div className="w-screen mt-10">
          <div className="container m-auto px-8">
            <p className="text-5xl p-4 font-light">
              OUR{' '}
              <span className="text-colorsecundary font-bold">PORTFOLIO</span>
            </p>
            <div className="w-auto font-light">
              <p>
                These are our projects, from the ones we are currently working
                on to the ones we have already sold or failed.
              </p>
              <p>
                If you are looking for our Incubator portfolio, check our{' '}
                <a
                  href="https://incubator.builduplabs.com/#startups"
                  className="text-colorsecundary"
                  onClick={() => {
                    fathom.trackEvent('incubator-porfolio');
                  }}
                >
                  Incubated startups
                </a>
                .
              </p>
            </div>
            <div className="flex flex-row justify-center py-6 m-auto">
              {this.state.stages.map((info) => (
                <FilterButton
                  key={info.id}
                  label={info.title}
                  isActive={this.state.activeButton === info.id ? true : false}
                  click={() => {
                    fathom.trackEvent(`portfolio-${info.title.toLowerCase()}`);
                    this.setState({ activeButton: info.id });
                  }}
                ></FilterButton>
              ))}
            </div>
          </div>
          <ProjectList projects={this.getProjects()} />
        </div>
      </section>
    );
  }
}

export default Portfolio;

import { Link } from 'gatsby';
import React, { useState } from 'react';
import siteLogo from '../images/logo_v2.svg';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="bg-colorprimary-dark-opEE fixed left-0 right-0 top-0 z-50">
      <div className="flex flex-wrap items-center justify-between mx-auto z-50 px-5">
        <Link className="flex items-center no-underline text-white" to="/">
          <img
            alt="BUL Logo"
            className="fill-current max-h-full h-8 p-1 absolute"
            src={siteLogo}
          />
        </Link>

        <button
          className="block lg:hidden border border-white flex items-center px-3 py-2 my-4 rounded text-white"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } lg:block lg:flex lg:items-center w-full lg:w-auto `}
          onClick={() => toggleExpansion(!isExpanded)}
        >
          {[
            {
              route: `/#about`,
              title: `OUR MISSION`,
            },
            {
              route: `/#portfolio`,
              title: `PORTFOLIO`,
            },
            {
              route: `/#team`,
              title: `TEAM`,
            },
            {
              route: `/#applyto`,
              title: `APPLY`,
            },
            {
              route: `/#applyto`,
              title: `INCUBATOR`,
              redirect: 'https://incubator.builduplabs.com/',
            },
            {
              route: `/#contacts`,
              title: `CONTACTS`,
            },
          ].map((link) => {
            if (link.redirect) {
              return (
                <a
                  className="border-b-2 border-transparent hover:border-white block lg:inline-block no-underline text-white text-xs text-center tracking-superwide px-3 py-5 mt-4 lg:mt-0"
                  key={link.title}
                  href={link.redirect}
                >
                  {link.title}
                </a>
              );
            }

            return (
              <AnchorLink
                className=" border-b-2 border-transparent hover:border-white block lg:inline-block no-underline text-white text-xs text-center tracking-superwide px-3 py-5 mt-4 lg:mt-0"
                key={link.title}
                to={link.route}
              >
                {link.title}
              </AnchorLink>
            );
          })}
        </nav>
      </div>
    </header>
  );
}

export default Header;

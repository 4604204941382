/*global fathom*/
import React from 'react';
import PropTypes from 'prop-types';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import JSONApply from '../../content/apply.json';

function Column({ title, description, btnText, btnLink, event }) {
  return (
    <div className="flex flex-col w-auto justify-around">
      <h3 className="text-xl font-bold py-3">{title}</h3>
      <p className="text-lg">{description}</p>
      <div className="py-3 mt-6 text-center">
        <OutboundLink
          href={btnLink}
          rel="noreferrer noopener"
          target="_blank"
          onClick={() => {
            if (event) {
              fathom.trackEvent(event);
            }
          }}
          className="h-full py-3  px-4 text-lg bg-transparent font-light border border-colorprimary hover:border-colorsecundary"
        >
          {btnText}
        </OutboundLink>
      </div>
    </div>
  );
}
Column.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  btnText: PropTypes.string,
  btnLink: PropTypes.string,
  event: PropTypes.string,
};

function ApplyTo() {
  return (
    <section
      id="applyto"
      className="text-center flex items-stretch bg-colorprimary-lighter"
    >
      <div className="container m-auto px-8 my-8">
        <div className="text-5xl p-4 font-light">
          <span className="inline">APPLY TO </span>
          <h2 className="inline text-colorsecundary font-bold">
            BUILD UP LABS
          </h2>
        </div>
        <div className="grid grid-cols-1 gap-4 py-6 px-12 text-left md:grid-cols-3 font-light">
          {JSONApply.columns.map((info) => (
            <Column key={info.title} {...info} />
          ))}
        </div>
      </div>
    </section>
  );
}

export default ApplyTo;
